.Tilt {
    perspective: 1500px;
    transform-style: preserve-3d;
    background-color: var(--secondary-background-color);
    height: 300px;
    width: 19rem;
    border-radius: 6px;
    margin-bottom: 3%;
  }
  
  .Tilt-inner {
    backface-visibility: hidden;
    transition: transform 1.5s;
    padding: 0%;
    z-index: 2;
    display: flex;
  }
  
  .Tilt:hover .Tilt-inner {
    transform: rotateY(5deg) rotateX(5deg);
  }
  
  .card-title{
    padding: 0% !important;
    margin: 6%;
    font-family: var(--primary-font-family);
    font-weight: var(--primary-font-weight-bold);
    font-size: var(--primary-card-font-size);
  }

  .Tilt-Card-items{
    display: flex;
    flex-direction: column;
  }
  
  .Tilt-Card-Image{
    padding-left: 0%;
    padding-left: 18%;
  }
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import "../../css/Theme/colors.css";


#myVideo {
    object-fit: cover;
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
}
.video-nav{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.hero-background {
    background-image: url("../../assets/hero.png");
    background-repeat: no-repeat;
    background-size:cover;
    
}

.hero-text {
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 3rem;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10%;
}

.header-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 28.1rem;
    /* background-color: #4f07cc; */
}

.header-row nav {
    /* width: 95%; */
}

.header-row nav .navbar-brand {
    font-family: 'Times New Roman', Times, serif;
    font-size: x-large;
    font-weight: 700;
}

.header-row nav .navbar-collapse {
    justify-content: space-between;
}

.login-btn {
    border: 2px solid rgb(69, 22, 139);
    border-radius: 20px;
    font-size: medium;
    background-color: white;
    color: rgb(25, 0, 61);
    ;
}

.sign-up-btn {
    border: 2px solid;
    background-color: rgb(25, 0, 61);
    border-radius: 20px;
    color: white;
    font-size: medium;
}

.header-text {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    color: white;
}

.header-text h1 {
    font-size: 90px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.header-text h4 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 100;
}

.get-started-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.get-started-btn button {
    background-color: rgb(23, 1, 54);
    border-radius: 25px;
    height: fit-content;
    color: white;
    font-size: medium;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.learn-more-icon {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.modal-content {
    border-radius: 0%;
}

.sign-up-col {
    height: fit-content;
    border-radius: 0 10px 10px 0;
}

.sign-up-col .material-symbols-outlined {
    position: absolute;
    left: 90%;
    top: 2vh;
    cursor: pointer;
}

.login-modal-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.signin-modal-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.signin-modal-col .material-symbols-outlined{
    position: absolute;
    left: 90%;
    top: 2vh;
    cursor: pointer;
}
.login-now-heading {
    font-family: 'Times New Roman', Times, serif;
}

.login-form-fields {
    display: flex;
    align-items: center;
}
.signin-form-fields{
    display: flex;
    align-items: center;
}
.signin-form-fields label{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.signin-form-fields form{
    width: 90%;
}
#submit-signin-button{
    width: 90%;
}
.login-form-fields label {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.login-form-fields form{
    width: 100%;
}
#otp-input-form-group {
    display: none;
}

.send-otp-button {
    width: 290px;
}
.sign-in-link :hover{
    cursor: pointer;
}
.login-link :hover{
    cursor: pointer;
}
.forgot-password{
    cursor: pointer;
}

#recovery-modal-col-id{
    display: none;
}
@media (max-width: 700px) {
    .header-text h1 {
        font-size: 50px;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
    }
}

.primary-button {
    color: var(--primary-color) !important;
    background-color: var(--primary-background-color) !important;
    border-radius: 3rem !important;
    height: 2.5rem;
    width:max-content;
    font-family: var(--primary-font-family) !important;
    font-weight: var(--primary-font-weight-normal) !important;
    font-size: var(--primary-font-size) !important;
}
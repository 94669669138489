#myVideo2 {
    object-fit: cover;
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
    /* z-index: 2; */
}

.video-2-row {
    background: linear-gradient(to right, black, rgb(205, 203, 203));
    position: relative;
    height: 100vh;
    display: flex;
    /* z-index: -1; */
}

.why-abroad-text {
    display: flex;
    flex-direction: column;
    /* z-index: 3; */
}

.why-abroad-text h1 {
    font-size: 60px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: white;
}
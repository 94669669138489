.user-login-row{
    background: linear-gradient(to right, black, rgb(205, 203, 203));
    height: fit-content;
}
.login-register-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: xx-large;
}
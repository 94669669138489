.countrylist-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: fit-content;
    margin-top: 15px;
    margin-bottom: 15px;
}

.countrylist-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 22%;
    height: fit-content;
    /* height: fit-content; */
}
.countrylist-item img{
    height: 35vh;
}
.image-container{
    position: relative;
}
.country-flag-icon{
    position: absolute;
    bottom: 0;
    left: 50%;
    top: 100%;
    transform:  translate(-50%,-50%);
    border: 7px solid white;
    border-radius: 50%;
    max-height: 90px;
    /* border-radius: 50%;
    width: 50px; 
    height: 20px; */
}
.country-name{
    margin-top: 20px;
}
.countrylist-item .card-body{
    margin-top: 40px;
    justify-content: space-between;
}
.read-more{
    /* font-weight: bold; */
    color: rgb(0, 102, 255);
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .countrylist-row {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .countrylist-item{
        border-radius: 5px;
    }
    .image-container img{
        height: 8vh;
    }
    .country-flag-icon{
        max-height: 30px;
        border: 2px solid white;
    }
    .country-name{
        font-size: 5px;
    }
    .countrylist-item .card-body{
        margin-top: 5px;
    }
    .country-text{
        font-size: 4px;
        margin-bottom: 0;
    }
    .read-more{
        font-size: 4px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
  }


.signup-row {
    min-height: 100vh;
}

.sign-up-mit {
    background-image: url('../../assets/MIT.jpg');
    background-size: cover;
    background-position: center;
    background-color: #cccccc;
    background-repeat: no-repeat, repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signin-forms {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signin-stepper {
    width: 80%;
}

.singunp-step-form {
    margin-top: 50px;
    width: 70%;
    height: fit-content;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hr-line {
    height: 1px;
    width: 60%;
}

.login-now-from-signup {
    cursor: pointer;
}

.login-now-from-signup :hover {
    transition: 0.5s;
    color: rgb(0, 106, 255);
    text-shadow: 2px 2px 5px rgb(165, 157, 157);
}

.form-input-field label {
    background-color: white;
}

.MuiInputLabel-shrink {
    margin-top: -3px;
}

.css-4jnixx-MuiStack-root>.MuiTextField-root {
    width: 100%;
}

.signup-form-btn-row {
    justify-content: end;
}

/* .react-tel-input .form-control {
    background-color: #f8f9fa;
    cursor: not-allowed;
}
.react-tel-input {
    width: 300px;
} */

/* .PhoneInput {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.PhoneInput input{
    width: 50%;
}
.PhoneInputCountry {
    display: flex;
    flex-direction: row-reverse;
}

.PhoneInputCountry select {
    width: 25%;
}

.PhoneInputCountryIcon {
    width: 25%;
}

.PhoneInputCountryIconImg {
    height: fit-content;
    width: 100%;
} */

.react-tel-input .form-control{
    /* z-index: 1; */
    /* left: 10%; */
    /* width: 100%; */
    border-radius: 0px;
}
.react-tel-input .flag-dropdown{
    width: 10%;
}
.degree-planning-options{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.degree-planning-option{
    height: fit-content;
    border: #cccccc solid 1px;
    border-radius:5px;
    background-color: #fcfbfb;
    margin: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.degree-icons{
    border-radius: 50%;
    height: 70px;
    width: 70px;
}
.selected-degree{
    /* background-color: rgb(0, 0, 0); */
    /* color: white; */
    /* transition: 1.2s; */
    border: #2b008f solid 3px;
}

.term-planning-row{
    align-items: center;
    justify-content: center;
}
.term-planning-options{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.term-planning-option{
    height: fit-content;
    width: fit-content;
    border: #cccccc solid 1px;
    border-radius:5px;
    background-color: #fcfbfb;
    margin: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.term-period{
    font-weight: bold;
    font-size: medium;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.term-name{
    color: #7c7c7c;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: small;
    /* font-style: oblique; */
}
.season-image{
    height: 30px;
}
.selected-term{
    border: #2b008f solid 3px;
    /* z-index: 2; */
}
.tnc-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
@media (max-width: 768px) {
    .signin-stepper {
        width: auto;
    }
    /* .term-planning-option{
        width: 100%;
    } */
}
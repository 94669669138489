.user-dashboard-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 100%; */
}

.user-profile-col-4 {
    /* background-color: aqua; */
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
    clip-path: inset(0px -15px 0px 0px);
}

.user-profile-pic {
    height: 100px;
    width: 100px;
}

.user-username {
    font-weight: lighter;
    font-size: 25px;
}

.user-degree-intake {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.user-degree-intake .col-md {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.user-degree-header {
    color: rgb(160, 159, 159);
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.edit-pencil{
    cursor: pointer;
}
.user-degree-icon {
    height: 30px;
    width: 30px;
}

.degree-name {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
}

.loan-finder {
    background-color: rgb(0, 92, 57);
    width: 100%;
    border-radius: 10px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
}

.loan-img {
    height: 75px;
    width: 75px;
}

.loan-finder-button {
    background-color: white;
    width: 100%
}

.user-profile-nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.user-profile-nav .nav-link {
    /* color: rgb(45, 0, 113); */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: lighter;
}

/* .nav-link.active{
    font-weight: light;
    color: rgb(45, 0, 113);
    transition: 1.2s;
    transform: scale(1.2);
} */
.user-profile-nav .nav-link span {
    font-weight: lighter;
}

.active-profile-item {
    color: rgb(45, 0, 113);
}

.undergrad-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    margin: 1%;
}

.undergrad-forms {
    margin: 0.5%;
    border: solid 1px rgb(213, 213, 213);
    border-bottom: 0;
    border-radius: 5px;
    height: 50vh;
    overflow-y: auto;
    /* display: flex;
    flex-direction: row;
    justify-content: center; */
}

.next-prev-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.next-prev-btn span {
    cursor: pointer;
}

.select-score {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
/* .select-score fieldset{
    height: 100%;
} */
.grad-year-select {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.dashboard-btns {
    background-color: transparent;
    border: none;
}

.btn-english-test {
    border: solid 1px rgb(47, 1, 106);
}

.btn-english-test:hover {
    background-color: rgb(47, 1, 106);
    color: white;
}

.english-active {
    background-color: rgb(47, 1, 106);
    color: white;
}

.dashboard-english-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    padding: 1%;
}

.english-test-options-row {
    display: flex;
    flex-direction: row;
}

.eng-helper-text {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
}

.eng-test-form {
    height: 45vh;
    overflow: auto;
}

/* .apt-test-form{
    height: 45vh; 
    overflow: auto; 
} */
.dashboard-skill-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    padding: 1%;
}

.jobexp-form {
    height: 55vh;
    overflow: auto;
}

.dashboard-jobexp-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    padding: 1%;
}

.job-nature-options {
    margin: auto;
}

.job-nature-option {
    border: #cccccc solid 1px;
    border-radius: 5px;
    background-color: #fcfbfb;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.selected-job-nature {
    background-color: #2b008f;
    color: white;
    border: none;
}

.dashboard-research-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    padding: 1%;
}

.research-form {
    height: 45vh;
    overflow: auto;
}

@media (max-width: 768px) {
    .user-profile-nav .nav-link {
        font-size: 10px;
    }
}

@media (max-width: 576px) {
    .user-profile-nav .nav-link {
        font-size: 5px;
    }
}
.blog-section {
    background-color:#ffffff;
  }
  
  .blog-section-header-1 {
    font-size: var(--primary-section-header-font-size-1);
    font-weight: var(--primary-font-weight-bold);
  }
  
  .blog-section-header-2 {
    font-size: var(--primary-section-header-font-size-2);
    font-weight: var(--primary-font-weight-light);
  }
  
  .blog-carousel-section {
    padding-top: 5%;
    display: flex;
    justify-content: space-between;
  }
  
  .blog-section-div {
    padding: 5% 10%;
    display: flex;
    flex-direction: column;
  }
  
  .blog-view-all {
    font-size: var(--primary-section-header-font-size-2);
    font-weight: var(--primary-font-weight-bold);
  }
  
  .blog-section-header {
    display: grid;
    grid-template-areas:
      "header header header header"
      "main main main  right";
    gap: 10px;
  }
  
  .item1 {
    grid-area: header;
  }
  .item2 {
    grid-area: main;
  }
  
  a {
      text-align: right;
  }
  
.feature-tabs-section {
    margin: var(--primary-section-margin);
    color: var(--primary-text-color) !important;
}

.feature-tab-box {
    padding: 10% 0% !important;
    width: 100% !important;
}

.feature-tabs-div {
    width: 100% !important;
}

.feature-tab-header-box {
    box-shadow: none !important;
    padding: 0 !important;
    width: 60% !important;
}

.feature-tab {
    background-color: white !important;
    color: #000000 !important;
    font-family: var(--primary-font-family) !important;
    font-weight: var(--primary-font-weight-bold) !important;
    font-size: var(--secondary-bar-header-font-size) !important;
    padding: 0 !important;
    align-items: flex-start !important;
}


.swipeable-views {
    padding: 0 !important;
    margin: 0 !important;
}
.steps-row{
    background-image: linear-gradient(180deg , #010010 5%, rgba(0, 0, 0, 0.353)  300%), url("../../assets/foreign-students.jpg");
    background-size: cover;
    background-position: center;
    background-color: #cccccc;
    background-repeat: no-repeat, repeat;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}
.step-lists{
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: space-evenly;
    height: fit-content;
}
.step-list{
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    height: fit-content;
    color: white;
}
.steps-row h4{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.value-for-visa{
    font-size: 50px;
}
.icon-container{
    position: relative;
}
.step-number{
    background-color: rgb(0, 115, 255);
    border-radius: 5px;
    color: white;
    height: 60px;
    width: 90px;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
}
.step-icon{
    position: absolute;
    bottom: 0;
    left: 47%;
    top: 70%;
    font-size: 50px;
    font-weight: 100;
    transform:  translate(-50%,-50%);
}
.step-list h5{
    margin-top: 25px;
    margin-bottom: 20px;
}
.step-list p{
    font-size: small;
}
.step-lists{
    margin-top: 50px;
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .step-number{
        border-radius: 5px;
        height: 30px;
        width: 45px;
        display: flex;
        flex-direction: column;
    }
    .step-number span{
        font-size: 10px;
    }
    .step-icon{
        font-size: 25px;
    }
    .value-for-visa{
        font-size: 15px;
    }
    .step-list p{
        font-size: 3px;
    }
    .step-list h5{
        font-size: 6px;
        margin-top: 15px;
        margin-bottom: 0px;
    }
    .expert-advisory-header{
        font-size: 20px;
    }
    .step-lists{
        margin-top: 8px;
        margin-bottom: 5px;
    }
    .steps-row h4{
        font-size: 15px;
    }
  }
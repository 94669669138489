.contact-us-row{
    background-image: url("../../assets/contact-us.jpg");
    background-size: cover;
    background-position: center;
    background-color: #cccccc;
    background-repeat: no-repeat, repeat;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}
.contact-us-form{
    margin-top: 20px;
    margin-bottom: 20px;
    height: 60vh;
    border-radius: 10px;
    box-shadow: rgba(254, 254, 255, 0.2) 0px 8px 24px;
    background-color: rgba(255, 255, 255, 0.351);
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: fit-content;
}
.form-row{
    display: none;
    flex-direction: column;
    justify-content: center;
}
.expand-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 60vh;
    cursor: pointer;
}
.expand-btn span{
    font-size: 40px;
}
.collapse-btn{
    display: none;
    flex-direction: column;
    justify-content: center;
    min-height: 60vh;
    cursor: pointer;
}
.collapse-btn span{
    font-size: 40px;
}
/* .expand-btn:hover + .form-row{
    transition: 1.2s;
   display: flex;
} */
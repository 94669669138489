@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');

.details-row {
    height: fit-content;
    top: 50px;
}

.detail {
    width: 100%;
    color: rgb(33, 1, 119);
    font-weight: bold;
}

.detail-list {
    background-color: rgb(217, 217, 217);
    width: 100%;
    height:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.detail-list h6 {
    font-size: larger;
    font-weight: bold;
    color: rgb(33, 1, 119);
}

.detail-list h2 {
    font-weight: bold;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.grid-container {
    justify-content: center;
    padding: 10px;
}
.grid-item {
    display: flex;
    flex-direction: row;
    background-color: rgba(255, 255, 255, 0.93);
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20px;
  }
  .grid-item .material-symbols-outlined{
    color: rgb(69, 22, 139);
    font-size:xx-large;
  }
  .grid-item .feature-title{
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    font-weight: bold;
  }
  .feature-details{
    font-size: 15px;
    font-weight: 300;
  }
@import "../../css/Theme/colors.css";

.feature-items-section{
    margin: 7% 10%;
}

.feature-list-section{
    display: flex;
    align-self: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 0%;
}

.feature-item-header{
    font-weight: var(--primary-font-weight-bold);
}

.feature-item-header-2{
    font-size: var(--primary-section-header-font-size-2);
}

.feature-item-header-1{
    font-size: var(--primary-section-header-font-size-1);
    margin-bottom: 7%;
}
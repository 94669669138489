.tab-card {
    display: flex;
    width: 100%;
    padding: 0 !important;
    justify-content: space-between;
}

.tab-card-info {
    display: flex;
    flex-direction: column;
    width: 45%;
    justify-content: space-between;
}

.feature-tabs-title-1 {
    font-size: var(--primary-section-header-font-size-1);
    font-weight: var(--primary-font-weight-bold);
}

.feature-tabs-title-2 {
    font-size: 2rem;
    font-weight: var(--primary-font-weight-light);
    padding: 2% 0%; 
}

.feature-tabs-description {
    font-size: var(--secondary-bar-header-font-size);
    color: var(--secondary-text-color);
    font-weight: var(--primary-font-weight-light);
}

.feature-tab-card-home-button {
    color:var(--primary-icon-button-color) !important;
    background: var(--primary-icon-button-background) !important;
    border-radius: var(--primary-icon-button-border-radius) !important;
    margin: 2% 0% !important;

}

.feature-tab-card-cta-button {
    color: #000 !important;
    background: var(--cta-button-background) !important;
    border-radius: 3rem !important;
    height: 2.5rem;
    width:max-content;
    font-family: var(--primary-font-family) !important;
    font-weight: var(--primary-font-weight-normal) !important;
    font-size: var(--secondary-bar-header-font-size) !important;
    box-shadow: none !important;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --primary-color: #6c2ff5;
  --secondary-color: #6c757d;
  --primary-background-color: #ffffff;
  --secondary-background-color: #f8f8f8;
  --primary-text-color: #000000;
  --primary-tab-bar-color: #6c63ff;
  --secondary-text-color: #5C5C5C;
  --primary-icon-button-color: #d1bcff;
  --primary-icon-button-background: #F5F0FF;
  --primary-icon-button-border-radius: 0.375rem;
  --primary-font-family: "Poppins", sans-serif;
  --primary-font-size: 0.875rem;
  --primary-card-font-size: 1.125rem;
  --primary-font-weight-normal: 500;
  --primary-font-weight-light: 400;
  --primary-font-weight-bold: 700;
  --primary-section-header-font-size-2: 1.125rem;
  --primary-section-header-font-size-1: 3rem;
  --secondary-bar-header-font-size: 0.875rem;
  --primary-title-line-height: 1rem;
  --primary-blog-description-font-size: 0.75rem;
  --primary-section-margin: 7% 10%;
  --cta-button-background: #E3D7FF;
  --secondary-section-background: #F9FCFE;
}

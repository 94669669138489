.smart-feature-row{
    background-color: #cccccc;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.smart-feature-row h5{
    color: rgb(18, 3, 102);
    font-weight: bold;
}
.smart-feature-row h6{
    color: rgb(83, 81, 81);
    font-size: 20px;
}
.smart-feature-lists{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
   
}
.smart-feature-list{
    background-color: white;
    border-radius: 20px;
    box-shadow: rgba(93, 95, 97, 0.2) 0px 8px 24px;
    /* height: 20vh; */
    padding: 40px;
}
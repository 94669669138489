.premium-feature-row {
    background-image: linear-gradient(60deg , #000000 2%, rgba(255, 224, 224, 0)  100%), url("../../assets/meeting-handshake.jpg");
    background-size: cover;
    background-position: center;
    background-color: #cccccc;
    background-repeat: no-repeat, repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    /* filter: grayscale(60%); */
}
@media (max-width: 1000px) {
    .premium-feature-row {
      justify-content: center; /* Set the height for small screens */
      height: fit-content;
    }
  }
.premium-feature-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.premium-feature-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.premium-feature-item img {
    border-radius: 50%;
    border: 20px solid rgb(43, 0, 130);
    height: 200px;
    width: 200px;
}
.universities-section {
  background-color: var(--secondary-section-background);
}

.universities-section-header-1 {
  font-size: var(--primary-section-header-font-size-1);
  font-weight: var(--primary-font-weight-bold);
}

.universities-section-header-2 {
  font-size: var(--primary-section-header-font-size-2);
  font-weight: var(--primary-font-weight-ligh);
}

.universities-carousel-section {
  padding-top: 5%;
  display: flex;
  justify-content: space-between;
}

.universities-section-div {
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
}

.university-view-all {
  font-size: var(--primary-section-header-font-size-2);
  font-weight: var(--primary-font-weight-bold);
}

.universities-section-header {
  display: grid;
  grid-template-areas:
    "header header header header"
    "main main main  right";
  gap: 10px;
}

.item1 {
  grid-area: header;
}
.item2 {
  grid-area: main;
}
.item3 {
  grid-area: right;
  text-align: right !important;
}

a {
    text-align: right;
}
